/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import GenericModal from "../../Modal/GenericModal";
import { Vocabulary } from "../../Utils/Vocabulary";
import { ClientData } from "./Clients";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { updateData } from "../../Services/updateData";
import { postData } from "../../Services/postData";
import { localUrlEnum, urlEnum } from "../../Utils/urlEnum";
import { isMobile } from "../../Utils/utils";
import moment from "moment";
import DinamicTabs from "../DinamicTabs";
import { MainTableProps } from "../MainTable";
import MUIDataTable from "mui-datatables";
import { CarOrderSource, tableLayoutOnMobile } from "../../Utils/Constants";
import DocumentsForClients from "./DocumentsForClients";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { clientDocType } from "../../Utils/autocompleteEnum";
import { CacheContext } from "../../Context/cacheContext";
import { VehicleModel } from "../../Models/VehicleModel";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import style from "../../Styles/mainHeader.module.css";
import { CarOrderModel } from "../Sales/MainTableSalesOnDemand";
import { getData } from "../../Services/getData";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteData } from "../../Services/deleteData";
import PreviewPDF from "../Generic/PDFViewer";
import PreviewImage from "../Generic/PreviewImage";
import DownloadDoc from "../VehicleDasboard/DownloadDoc";
import PreviewIcon from "@mui/icons-material/Preview";
import CircleIcon from "@mui/icons-material/Circle";

const MIN_SEARCH_LEN = 3;

type ClientProps = {
  open: boolean;
  title: string;
  editedClient?: ClientData | null;
  onClose: (shouldRefetch?: boolean) => void;
  handleOpenGDPR?: any;
  handleOpenBeforeContract?: (
    client: ClientData,
    firmaId: string,
    carId: string
  ) => void;
  handleFirmaId?: any;
};

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const initialState: ClientData = {
  companyName: "",
  lastName: "",
  firstName: "",
  email: "",
  phone: "",
  CNP: "",
  series: "",
  number: "",
  CUI: "",
  nrRegCom: "",
  street: "",
  city: "",
  country: "",
  isLegalPerson: false,
};

const initialVehicleState: CarOrderModel = {
  nume_complet: "",
  localitate: "",
  email: "",
  telefon: "",
  brand_id: null,
  model_id: null,
  buget: "",
  detalii_extra: "",
  status_id: null,
  user_id: null,
  sursa_comanda: Object.values(CarOrderSource)[0],
  vehicle_name: "",
  vehicul_id: null,
  logs: [],
};

function TabPanel(props: MainTableProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        backgroundColor: "#ebebeb49",
        padding: 10,
        borderRadius: "0px 0px 10px 10px",
      }}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

export default function Client(props: ClientProps) {
  const {
    open,
    title,
    editedClient,
    onClose,
    handleOpenGDPR,
    handleOpenBeforeContract,
    handleFirmaId,
  } = props;
  // const [openUploadGDPR, setOpenUploadGDPR] = useState(false);
  const [openUploadDoc, setOpenUploadDoc] = useState(false);
  const [clientDocs, setClientDocs]: any = useState([]);
  const [docId, setDocId] = useState<any>(null);
  const [isSignature, setIsSignature] = useState<any>(null);

  const [firmaId, setFirmaId] = useState<any>(null);
  const [renderOptionsValue, setRenderOptionsValue] = useState("0");

  const [value, setValue] = useState(0);
  const [GDPRFile, setGDPRFile] = useState<any>(null);
  const [data, setData] = useState<ClientData>(initialState);
  const [countries, setCountries] = useState<any>();
  const [cities, setCities] = useState<any>();
  const cacheContext = useContext(CacheContext);
  const [editVehicle, setEditVehicle] = useState(false);
  const [searchResult, setSearchResult]: any = useState(null);
  const [vehicleName, setVehicleName] = useState<string>("");
  const [vehicle, setVehicle]: any = useState(null);
  const [openSelectVehicle, setOpenSelectVehicle] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [deletedId, setDeletedId]: any = useState();
  const [filePath, setFilePath] = useState<string>();

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [vehicleData, setVehicleData] =
    useState<CarOrderModel>(initialVehicleState);

  useEffect(() => {
    if (editedClient) {
      setData(editedClient);
      getClientsDocuments();
    } else setData(initialState);
  }, [editedClient]);

  /**
   *
   * @param event
   */
  function handleChangeTextFields(event: any) {
    setData({
      ...data,
      [event.target.name]:
        event.target.name != "email"
          ? capitalize(event.target.value)
          : event.target.value,
    });
  }
  /**
   *
   */
  useEffect(() => {
    getCountriesAPI();
  }, []);

  /**
   *
   */
  const getCountriesAPI = () => {
    setCountries(cacheContext.cache?.clientsCountries);
  };

  /**
   *
   */
  useEffect(() => {
    if (data.country) {
      const cities = countries?.filter(
        (country: any) => country.country == data.country
      );
      if (cities.length) setCities(cities[0]?.cities);
    }
  }, [data.country]);

  const schema = Yup.object().shape({
    firma: Yup.object().required("Acest camp este obligatoriu!"),
    // descriere_financiar: Yup.string().required("Acest camp este obligatoriu"),
    // status_financiar: Yup.object().required("Acest camp este obligatoriu!"),
    tip_fisier: Yup.object().required("Acest camp este obligatoriu!"),
  });

  const { control, watch, handleSubmit, reset }: any = useForm({
    resolver: yupResolver(schema),
  });

  console.log(watch("firma")?.id);

  const columns = [
    {
      name: "id",
      label: " ",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      label: "Nume fisier",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "file",
      label: "",
      options: {
        display: false,
        filter: true,
        sort: false,
      },
    },
    {
      name: "vehicul",
      label: "Vehicul asociat",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "sendEmailAt",
      label: "Data email",
      options: {
        filter: true,
        sort: false,
        boolean: true,

        customBodyRender: (isSignature: any) => (
          <div>
            {isSignature === "0" ? null : (
              <Tooltip title={"Data email"}>
                <Typography>{isSignature}</Typography>
              </Tooltip>
            )}
          </div>
        ),
      },
    },
    {
      name: "isSignature",
      label: "Semnat",
      options: {
        filter: true,
        sort: false,
        boolean: true,

        customBodyRender: (isSignature: any) => (
          <div>
            {isSignature === "0" ? (
              <Tooltip title={"Nesemnat"}>
                <CircleIcon style={{ color: "#7a7a7a63" }} />
              </Tooltip>
            ) : (
              <Tooltip title={"Semnat"}>
                <CircleIcon style={{ color: "#49ab518c" }} />
              </Tooltip>
            )}
          </div>
        ),
      },
    },
    {
      name: "file",
      label: " ",
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (fileName: any, rowIndex: any) => {
          if (fileName === null) return null;
          const path = `${urlEnum.vehicles}/${fileName}`;
          return (
            <>
              <Button
                style={{ float: "right", fontSize: "10px" }}
                size="small"
                color="primary"
                variant="contained"
                onClick={() => {
                  setFilePath(path);
                  setFirmaId(clientDocs[rowIndex?.rowIndex]?.firmaId);
                  setDocId(clientDocs[rowIndex?.rowIndex]?.id);
                  setIsSignature(clientDocs[rowIndex?.rowIndex]?.isSignature);
                }}
              >
                {Vocabulary.open}
              </Button>
            </>
          );
        },
      },
    },
    {
      label: " ",
      name: "file",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        customBodyRenderLite: (rowIndex: any) => (
          <div style={{ float: "right", display: "flex" }}>
            <DeleteIcon
              style={{ height: 30, cursor: "pointer", color: "#4449" }}
              onClick={(event: any) => {
                setOpenModalDelete(true);
                setDeletedId(clientDocs[rowIndex]);
              }}
            ></DeleteIcon>
          </div>
        ),
      },
    },
  ];

  /**
   *
   */
  const options = {
    filter: false,
    viewColumns: false,
    download: false,
    responsive: tableLayoutOnMobile,
    sort: false,
    selectableRows: false as any,
    search: false,
    print: false,
    textLabels: {
      selectedRows: {
        text: "selectate",
        delete: "Sterge",
        deleteAria: "Sterge liniile selectate",
      },
      body: {
        noMatch: "Nu exista date!",
      },
    },
  };

  /**
   *
   * @param id
   */
  async function deleteClientFiles(id: any) {
    const url = `${urlEnum.clientFiles}/${id}`;
    const res = (await deleteData(url)) as any;
    if (!res || res?.error) {
      return;
    } else {
      getClientsDocuments();
      setOpenModalDelete(false);
    }
  }

  /**
   *
   * @param e
   * @returns
   */
  function domElementClick(path: any) {
    const type = path.split(".");
    const fileData = type[type.length - 1];

    if (fileData === "pdf" || fileData === "PDF")
      return (
        <PreviewPDF
          doc={docId}
          id={editedClient?.id}
          firmaId={firmaId ? firmaId : watch("firma")?.id}
          title={path}
          open={path ? true : false}
          onClose={() => {
            setFilePath(undefined);
          }}
          emailData={{
            to: editedClient?.email || "",
            subject: "Semnarea acordului GDPR",
            message: "GDPR",
            docPath: editedClient?.filePath || "",
          }}
          signature={true}
          isSign={isSignature === "1" ? false : true}
          url={path}
          resetClientFilePathForGDPR={(client: ClientData | null) => {
            if (client) {
              handleOpenGDPR && handleOpenGDPR(client, undefined);
              getClientsDocuments();
              setFilePath(undefined);
            }
          }}
        />
      );

    if (
      fileData === "jpg" ||
      fileData === "png" ||
      fileData === "jpeg" ||
      fileData === "webp" ||
      fileData === "gif" ||
      fileData === "svg"
    )
      return (
        <PreviewImage
          title={path}
          open={path ? true : false}
          onClose={() => {
            setFilePath(undefined);
          }}
          url={path}
        />
      );

    if (
      fileData === "docx" ||
      fileData === "doc" ||
      fileData === "xlsx" ||
      fileData === "xls"
    )
      return <DownloadDoc fileData={path} />;
  }

  /**
   * Get info from ANAF
   */
  function getInfoFromANAF() {
    if (data.CUI === null || data.CUI === "") return;
    const cui = data.CUI.replace("RO", "");
    const info = [
      {
        cui: cui,
        data: moment().format("YYYY-MM-DD"),
      },
    ];

    postData(`${urlEnum.clients}/anaf`, info)
      .then((response) => {
        if (response.data.company.length === 0) return;
        setData({
          ...data,
          companyName: response.data.company[0]?.date_generale?.denumire,
          street: `${response.data.company[0].adresa_domiciliu_fiscal?.ddenumire_Strada}, Nr. ${response.data.company[0]?.adresa_domiciliu_fiscal?.dnumar_Strada}`,
          city: response.data.company[0].adresa_domiciliu_fiscal
            ?.ddenumire_Judet,
          CUI: response.data.company[0].date_generale?.cui,
          nrRegCom: response.data.company[0].date_generale?.nrRegCom,
          country: "Romania",
        });
      })
      .catch((error) => {
        //
      });
  }

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  function getClientsDocuments() {
    const url = `${urlEnum.clientsDocuments}/${editedClient?.id}`;
    getData(url)
      .then((res) => {
        setClientDocs(res?.data?.clientsDocs);
      })
      .catch((err) => {
        //
      });
  }
  /**
   * Save client
   */
  function saveClient() {
    if (editedClient) {
      updateData(`${urlEnum.clients}/${editedClient?.id}`, data)
        .then((response) => {
          setData(initialState);
          onClose(true);
        })
        .catch((error) => {
          setData(initialState);
          onClose(false);
        });
    } else {
      postData(urlEnum.clients, data)
        .then((response) => {
          setData(initialState);
          onClose(true);
        })
        .catch((error) => {
          setData(initialState);
          onClose(false);
        });
    }
  }

  function handleChangeToggle(e: any, newValue: any) {
    setRenderOptionsValue(newValue);
    if (newValue === "0") {
      getClientsDocuments();
    }
  }

  /**
   *
   * @param event
   */
  function handleSearchVehicle(event: any) {
    const { value } = event.target;
    setVehicleName(value);
    if (value && value.length > MIN_SEARCH_LEN) {
      // fetch search data
      postData(`${urlEnum.searchVehicles}`, { search: value }).then(
        (response: any) => {
          setSearchResult(response.data);
        }
      );
    }
  }

  const onSubmit = () => {
    if (watch("tip_fisier")?.nume === "GDPR") {
      console.log(data);
      if (handleOpenGDPR) {
        handleOpenGDPR(data, watch("firma")?.id);
        getClientsDocuments();
        reset({
          firma: null,
          tip_fisier: null,
        });
      }
    } else {
      if (handleOpenBeforeContract && vehicle?.id) {
        handleOpenBeforeContract(data, watch("firma")?.id, vehicle?.id);
        getClientsDocuments();
      }
    }
  };

  const onError = (data: any) => {
    // eslint-disable-next-line no-console
    console.log(data); //E lasat aici pentru a vedeam campurile care nu sunt completate
  };

  /**
   *
   * @param item
   * @returns
   */
  const renderOptions = (item: string) => {
    switch (item) {
      case "0":
        return (
          <div style={{ marginRight: 10 }}>
            <MUIDataTable
              key={""}
              title={""}
              data={clientDocs}
              columns={columns}
              options={options}
            />
          </div>
        );

      case "1":
        return (
          <div>
            <form
              onSubmit={handleSubmit(onSubmit, onError)}
              // onSubmit={(event) => {

              //   event.preventDefault();
              //   if (handleOpenGDPR) handleOpenGDPR(data, watch("firma")?.id);
              // }}
              id="clientForm"
              method="get"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile() ? "column" : "row",
                  marginBottom: 10,
                  gap: 5,
                }}
              >
                <Controller
                  name="tip_fisier"
                  control={control}
                  defaultValue={null}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      freeSolo={false}
                      style={{ width: "100%" }}
                      {...field}
                      size="small"
                      getOptionLabel={(option: any) => option?.nume}
                      disableClearable
                      disablePortal
                      id="tip_fisier"
                      onChange={(event, value) => field.onChange(value)}
                      options={clientDocType}
                      renderInput={(params) => (
                        <TextField
                          error={!!error}
                          helperText={error?.message}
                          label="Tip fisier"
                          name="tip_fisier"
                          type="search"
                          inputRef={ref}
                          {...params}
                        />
                      )}
                    />
                  )}
                />
                {watch("tip_fisier")?.nume === "GDPR" ? (
                  <>
                    <Controller
                      name="firma"
                      control={control}
                      defaultValue={null}
                      render={({
                        field: { ref, ...field },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          freeSolo={false}
                          style={{ width: "100%" }}
                          {...field}
                          size="small"
                          disableClearable
                          disablePortal
                          id="firma"
                          onChange={(event, value) => {
                            field.onChange(value);
                            handleFirmaId(value);
                          }}
                          getOptionLabel={(option: any) => option.nume_firma}
                          options={cacheContext.cache?.company}
                          renderInput={(params) => (
                            <TextField
                              error={!!error}
                              helperText={error?.message}
                              label="Firma"
                              name="firma"
                              type="search"
                              inputRef={ref}
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                  </>
                ) : null}
              </div>
              {watch("tip_fisier")?.nume === "Antecontract" ? (
                <>
                  <Controller
                    name="firma"
                    control={control}
                    defaultValue={{ id: 0, nume_firma: "" }}
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        freeSolo={false}
                        style={{ width: "100%" }}
                        {...field}
                        size="small"
                        disableClearable
                        disablePortal
                        id="firma"
                        onChange={(event, value) => field.onChange(value)}
                        getOptionLabel={(option: any) => option.nume_firma}
                        options={cacheContext.cache?.company}
                        renderInput={(params) => (
                          <TextField
                            error={!!error}
                            helperText={error?.message}
                            label="Firma"
                            name="firma"
                            type="search"
                            inputRef={ref}
                            {...params}
                          />
                        )}
                      />
                    )}
                  />
                  {vehicle?.id ? (
                    <div style={{ display: "flex", margin: 10 }}>
                      <a
                        href={`${localUrlEnum.vehicles}/${localUrlEnum.vehicle}/${vehicle?.id}`}
                        target="popup"
                        style={{
                          textDecoration: "none",
                          color: "black",
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#1976d2",
                            marginLeft: 4,
                            fontSize: 17,
                          }}
                        >
                          {Vocabulary.associatedVehicle}: {vehicle?.numeComplet}{" "}
                          - {vehicle?.vin}
                        </Typography>
                      </a>
                      <EditIcon
                        style={{
                          marginLeft: 10,
                          cursor: "pointer",
                          color: "#1976d2",
                        }}
                        onClick={() => setOpenSearch(true)}
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => setOpenSearch(true)}
                      style={{
                        margin: 15,
                        color: "#f33",
                        fontSize: 17,
                        cursor: "pointer",
                        width: "100%",
                      }}
                    >
                      {Vocabulary.associateWithVehicle}
                    </div>
                  )}
                </>
              ) : null}
              <Button
                variant="contained"
                style={{ color: "white" }}
                color="primary"
                type="submit"
                id="clientForm"
                // onClick={() => {
                //   if (handleOpenGDPR) handleOpenGDPR(data,watch("firma").id);
                // }}
              >
                {Vocabulary.generate}
              </Button>
            </form>
          </div>
        );

      case "adauga":
        return (
          <div style={{ marginRight: 10 }}>
            <MUIDataTable
              key={""}
              title={""}
              data={clientDocs}
              columns={columns}
              options={options}
            />
          </div>
        );

      default:
        return (
          <div style={{ marginRight: 10 }}>
            <MUIDataTable
              key={""}
              title={""}
              data={clientDocs}
              columns={columns}
              options={options}
            />
          </div>
        );
    }
  };

  /**
   *
   */
  async function saveGDPRAgreement() {
    if (editedClient && editedClient?.id) {
      const formData = new FormData();
      formData.append("file", GDPRFile);
      await postData(
        `${urlEnum.clients}/uploadGDPR/${editedClient?.id}`,
        formData
      );
      setData(initialState);
      onClose(true);
    }
  }

  function handleResetFields() {
    reset({
      firma: null,
      tip_fisier: null,
    });
    setRenderOptionsValue("0");
    setValue(0);
  }

  return (
    <>
      <GenericModal
        open={open}
        onClose={() => {
          onClose(false);
          setData(initialState);
          handleResetFields();
        }}
        title={title}
      >
        <DinamicTabs
          name={""}
          labels={
            editedClient
              ? [Vocabulary.info, Vocabulary.documents]
              : [Vocabulary.info]
          }
          handleChangeTab={(e: any) => {
            setValue(value === 0 ? 1 : 0);
          }}
          openModalWindow={() => setOpenUploadDoc(true)}
          value={value}
        />
        <TabPanel value={value} index={0}>
          <ValidatorForm onSubmit={saveClient}>
            <Grid
              container
              spacing={isMobile() ? 1 : 2}
              style={{
                paddingBottom: 15,
                zIndex: 100,
                marginTop: 5,
                marginBottom: 10,
              }}
            >
              <Grid style={{ display: "flex" }} item lg={12} md={12} xs={12}>
                <Checkbox
                  onClick={(event: any) =>
                    setData({ ...data, isLegalPerson: event.target.checked })
                  }
                  checked={data.isLegalPerson}
                ></Checkbox>
                <div style={{ marginTop: 11 }}>{Vocabulary.isLegalPerson}</div>
              </Grid>

              {data.isLegalPerson ? (
                <Grid item md={12} xs={12}>
                  <TextValidator
                    size="small"
                    name="companyName"
                    label={Vocabulary.companyName}
                    fullWidth
                    validators={["required"]}
                    errorMessages={[Vocabulary.requiredField]}
                    value={data.companyName}
                    onChange={handleChangeTextFields}
                  />
                </Grid>
              ) : null}
              <Grid item md={6} xs={12}>
                <TextValidator
                  size="small"
                  name="lastName"
                  label={Vocabulary.lastName}
                  fullWidth
                  validators={["required"]}
                  errorMessages={[Vocabulary.requiredField]}
                  value={data.lastName}
                  onChange={handleChangeTextFields}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextValidator
                  size="small"
                  name="firstName"
                  label={Vocabulary.firstName}
                  fullWidth
                  validators={["required"]}
                  errorMessages={[Vocabulary.requiredField]}
                  value={data.firstName}
                  onChange={handleChangeTextFields}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextValidator
                  size="small"
                  name="email"
                  label={Vocabulary.email}
                  fullWidth
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    Vocabulary.requiredField,
                    Vocabulary.incorrectEmail,
                  ]}
                  value={data.email}
                  onChange={handleChangeTextFields}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextValidator
                  size="small"
                  name="phone"
                  label={Vocabulary.phone}
                  fullWidth
                  validators={["required"]}
                  errorMessages={[Vocabulary.requiredField]}
                  value={data.phone}
                  onChange={handleChangeTextFields}
                />
              </Grid>
              {!data.isLegalPerson ? (
                <>
                  <Grid item md={6} xs={12}>
                    <TextValidator
                      size="small"
                      name="series"
                      label={Vocabulary.series}
                      fullWidth
                      validators={["required"]}
                      errorMessages={[Vocabulary.requiredField]}
                      value={data.series}
                      onChange={handleChangeTextFields}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextValidator
                      size="small"
                      name="number"
                      label={Vocabulary.number}
                      fullWidth
                      validators={["required"]}
                      errorMessages={[Vocabulary.requiredField]}
                      value={data.number}
                      onChange={handleChangeTextFields}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item md={6} xs={12}>
                {data.isLegalPerson ? (
                  <TextValidator
                    size="small"
                    name="CUI"
                    label={Vocabulary.CUI}
                    fullWidth
                    validators={[
                      "required",
                      "matchRegexp:^([a-zA-Z]{2})?([0-9]{8}|[0-9]{7})$",
                    ]}
                    errorMessages={[
                      Vocabulary.requiredField,
                      Vocabulary.invalidCUI,
                    ]}
                    value={data.CUI}
                    onChange={handleChangeTextFields}
                    onBlur={getInfoFromANAF}
                  />
                ) : (
                  <TextValidator
                    size="small"
                    name="CNP"
                    label="CNP"
                    fullWidth
                    validators={[
                      "required",
                      "matchRegexp:^[1,2,5,6,7,8].*",
                      "matchRegexp:^.{13}$",
                      // "matchRegexp:^.{3}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])",
                    ]}
                    errorMessages={[
                      Vocabulary.requiredField,
                      Vocabulary.firstNumberCNP,
                      Vocabulary.numberCNP,
                      // Vocabulary.invalidCNP,
                    ]}
                    value={data.CNP}
                    onChange={handleChangeTextFields}
                  />
                )}
              </Grid>
              {data.isLegalPerson ? (
                <Grid item md={6} xs={12}>
                  <TextValidator
                    size="small"
                    name="nrRegCom"
                    label={Vocabulary.nrRegCom}
                    fullWidth
                    validators={["required"]}
                    errorMessages={[Vocabulary.requiredField]}
                    value={data.nrRegCom}
                    onChange={handleChangeTextFields}
                  />
                </Grid>
              ) : null}
              <Grid item md={6} xs={12}>
                {countries && (
                  <Autocomplete
                    id="country"
                    freeSolo={false}
                    disablePortal
                    getOptionLabel={(option: any) =>
                      option.country ? option?.country : option
                    }
                    size="small"
                    options={countries ? countries : []}
                    onChange={(
                      event: SyntheticEvent<Element, Event>,
                      newValue: any
                    ) => {
                      if (newValue) {
                        const value = Object.assign(newValue).country;
                        setData({
                          ...data,
                          city: null,
                          country: value,
                        });
                      } else {
                        setData({
                          ...data,
                          city: null,
                          country: null,
                        });
                      }
                    }}
                    sx={{ width: "100%" }}
                    value={data.country ? data?.country : null}
                    ListboxProps={{ style: { maxHeight: 280 } }}
                    isOptionEqualToValue={(option: any, value) =>
                      option?.country
                        ? option?.country.toLowerCase() === value.toLowerCase()
                        : option.toLowerCase() === value.toLowerCase()
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={Vocabulary.country}
                        required
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                {countries && (
                  <Autocomplete
                    id="city"
                    freeSolo={false}
                    disablePortal
                    getOptionLabel={(option: any) => option}
                    size="small"
                    options={cities ? cities : []}
                    onChange={(
                      event: SyntheticEvent<Element, Event>,
                      newValue: string | null
                    ) => {
                      setData({
                        ...data,
                        city: newValue,
                      });
                      //  setCities(newValue['cities' as keyof typeof newValue]);
                    }}
                    sx={{ width: "100%" }}
                    ListboxProps={{ style: { maxHeight: 280 } }}
                    value={data.city ? data?.city : null}
                    isOptionEqualToValue={(option, value) =>
                      option.toLowerCase() == value.toLowerCase()
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label={Vocabulary.city}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextValidator
                  size="small"
                  name="street"
                  label={Vocabulary.street}
                  fullWidth
                  validators={["required"]}
                  errorMessages={[Vocabulary.requiredField]}
                  value={data.street}
                  onChange={handleChangeTextFields}
                />
              </Grid>
            </Grid>
            <Grid container spacing={isMobile() ? 1 : 4}>
              <Grid item md={6} xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onClose(false)}
                  fullWidth
                >
                  {Vocabulary.cancel}
                </Button>
              </Grid>
              <Grid item md={6} xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  style={{ color: "white" }}
                >
                  {Vocabulary.save}
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {/* <Grid item lg={6} md={6} xs={6}>
            {data.isSignature ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  verticalAlign: "center",
                }}
              >
                <Typography>
                  {Vocabulary.signedGDPR}: &nbsp; &nbsp; &nbsp;
                </Typography>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    downloadPDF(
                      `${urlEnum.templates}/getDoc/${data.filePath}`,
                      data.filePath || "GDPR.pdf"
                    )
                  }
                >
                  {Vocabulary.downloadGDPR}
                </Button>
              </div>
            ) : (
              <>
                {data.id ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      verticalAlign: "center",
                    }}
                  >
                    <Typography>
                      {Vocabulary.signGDPR}: &nbsp; &nbsp; &nbsp;
                    </Typography>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        if (handleOpenGDPR) handleOpenGDPR(data);
                      }}
                    >
                      {Vocabulary.GDPR}
                    </Button>
                  </div>
                ) : null}
              </>
            )}
          </Grid>
          <Grid item md={12} xs={12}>
            {data.id ? (
              <UploadElement
                openModal={openUploadGDPR}
                handleModal={() => setOpenUploadGDPR(!openUploadGDPR)}
                handleSave={() => saveGDPRAgreement()}
                handleEditInfo={(event: any) => {
                  setGDPRFile(event[0]);
                }}
                title={Vocabulary.upload}
                disabled={false}
                fileName={data.filePath}
                file={data.filePath}
                fileIsRequired={true}
              >
                <div>test</div>
              </UploadElement>
            ) : null}
          </Grid> */}
          <ToggleButtonGroup
            exclusive
            aria-label="Render Buttons"
            style={{ float: "right", margin: 10 }}
            onChange={handleChangeToggle}
          >
            <Tooltip title={Vocabulary.seeTableTooltip} placement="top">
              <ToggleButton
                value="0"
                aria-label="centered"
                style={{
                  backgroundColor:
                    renderOptionsValue === "0" || renderOptionsValue === "2"
                      ? "#ff6633ab"
                      : "#fff",
                }}
              >
                {Vocabulary.allFiles}
              </ToggleButton>
            </Tooltip>
            <Tooltip title={Vocabulary.generateTooltip} placement="top">
              <ToggleButton
                value="1"
                aria-label="centered"
                style={{
                  backgroundColor:
                    renderOptionsValue === "1" ? "#ff6633ab" : "#fff",
                }}
              >
                {Vocabulary.generate}
              </ToggleButton>
            </Tooltip>
            <Tooltip title={Vocabulary.add} placement="top">
              <ToggleButton
                value="2"
                aria-label="centered"
                onClick={() => setOpenUploadDoc(true)}
              >
                {Vocabulary.add}
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
          {renderOptions(renderOptionsValue)}
        </TabPanel>
      </GenericModal>
      {/** SEARCH RESULTS */}
      <GenericModal
        open={openSearch}
        onClose={() => {
          setOpenSearch(false);
          setEditVehicle(false);
        }}
        title={""}
      >
        <div style={{ textAlign: "center" }}>
          <TextField
            autoFocus
            name="vehicleName"
            variant="standard"
            label={Vocabulary.associateWithVehicle}
            value={vehicleName ? vehicleName : ""}
            onChange={(event) => {
              handleSearchVehicle(event);
            }}
            style={{ margin: 10, width: "90%" }}
          />
        </div>
        <div className={style.searchWrapper}>
          <h3 style={{ fontWeight: 100 }}>{Vocabulary.searchResult}</h3>
        </div>
        <Divider />
        {searchResult && (
          <div
            className={style.searchWrapper}
            style={{ maxHeight: "700px", overflowY: "scroll" }}
          >
            <List sx={{ width: "90%", bgcolor: "background.paper" }}>
              {searchResult.map((vehicle: VehicleModel, index: number) => (
                <ListItem
                  alignItems="flex-start"
                  key={index}
                  className={style.searchListItem}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setVehicle(vehicle);
                    setOpenSearch(false);
                    setEditVehicle(false);
                    setOpenSelectVehicle(true);
                  }}
                >
                  <ListItemAvatar
                    style={{ margin: "0px 20px 0px 0px", width: 76 }}
                  >
                    {vehicle.vehicule_files_r?.length ? (
                      <div
                        className={style.iconWrapper}
                        style={{
                          backgroundImage: `url("/api/${vehicle.vehicule_files_r[0].file}")`,
                        }}
                      ></div>
                    ) : (
                      <div className={style.iconWrapper}>
                        <DirectionsCarIcon />
                      </div>
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${vehicle?.brand_r?.nume} ${vehicle?.model_r?.nume}`}
                    secondary={
                      <>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          VIN: {vehicle.vin.toUpperCase()}
                        </Typography>
                        <br />
                        {Vocabulary.status}: {vehicle?.status_vehicul_r?.nume}
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
        )}
        {/** END SEARCH RESULTS */}
      </GenericModal>
      <DocumentsForClients
        key={""}
        editedClient={editedClient}
        open={openUploadDoc}
        onClose={() => setOpenUploadDoc(false)}
        title={Vocabulary.uploadDoc}
        type={"test"}
        getNewData={() => getClientsDocuments()}
      ></DocumentsForClients>
      <GenericModal
        open={openModalDelete}
        onClose={() => setOpenModalDelete(false)}
        title=""
      >
        <div style={{ textAlign: "center" }}>
          <p style={{ fontSize: 20 }}>
            Sunteti sigur ca doriti sa stergeti {deletedId?.name}?
          </p>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              fullWidth
              color="secondary"
              style={{ margin: 10 }}
              onClick={() => setOpenModalDelete(false)}
            >
              {Vocabulary.no}
            </Button>
            <Button
              onClick={() => deleteClientFiles(deletedId?.id)}
              fullWidth
              variant="contained"
              style={{ color: "white", margin: 10 }}
            >
              {Vocabulary.yes}
            </Button>
          </div>
        </div>
      </GenericModal>
      {filePath ? domElementClick(filePath) : null}
    </>
  );
}
