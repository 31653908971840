/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Button,
  Dialog,
  DialogTitle,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../../Styles/genericModal.module.css";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { postData } from "../../Services/postData";
import { DropzoneArea } from "material-ui-dropzone";
import style from "../../Styles/modalServices.module.css";
import GenericModal from "../../Modal/GenericModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { tipFisier } from "../../Utils/autocompleteEnum";
import moment from "moment";
// import { deleteData } from "../Services/deleteData";
import { urlEnum } from "../../Utils/urlEnum";
import { getIconByFileFileType, isMobile } from "../../Utils/utils";
import { VehicleContext } from "../../Context/vehicleContext";
import { Vocabulary } from "../../Utils/Vocabulary";

function DocumentsForClients(props) {
  const { open, onClose, maxWidth, editedClient } = props;
  const vehicleContext = useContext(VehicleContext);

  const [openModalDelete, setOpenModalDelete] = React.useState(false);

  const [files, setFiles] = useState([]);
  const [fileExist, setFileExist] = useState(true);
  const [deleteSucces, setDeleteSucces] = useState(false);
  const [openModalImg, setOpenModalImg] = useState(false);
  var actualDate = new Date(); //To get the Current Date



  const schema = Yup.object().shape({
    date: Yup.string().required("Acest camp este obligatoriu"),
    // descriere_financiar: Yup.string().required("Acest camp este obligatoriu"),
    // status_financiar: Yup.object().required("Acest camp este obligatoriu!"),
    tip_fisier: Yup.object().required("Acest camp este obligatoriu!"),
  });

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (props.edit) {
      reset({
        date: props?.editData?.date ? moment(props?.editData?.date) : null,
        descriere_financiar: props?.editData?.descriere_financiar,
        suma_financiar: props?.editData?.suma_financiar,
        status_financiar: {
          id: "",
          nume: props?.editData?.status_financiar
            ? props?.editData?.status_financiar
            : "",
        },
        tip_fisier: {
          id: "",
          nume: props?.editData?.tip_fisier
            ? props?.editData?.tip_fisier
            : "",
        },
      });
    } else {
      reset({
        date: moment(actualDate),
        descriere_financiar: null,
        suma_financiar: "",
        status_financiar: null,
        tip_fisier: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.editData?.id]);

  /**
   *
   * @param {*} data
   */
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("client_id", editedClient?.id);
    formData.append("vehicul_id", null);
    formData.append("vin", null);
    formData.append("date", moment(data.date).format("YYYY-MM-DD HH:mm:ss"));
    formData.append("descriere_financiar", data.descriere_financiar ? data.descriere_financiar : " ");
    formData.append("suma_financiar", data.suma_financiar);
    formData.append("status_financiar", data?.status_financiar?.nume ? data?.status_financiar?.nume : null);

    if (props.edit) {
      if (files && files.length !== 0) {
        files.forEach((file) => {
          formData.append("file_id", file);
          if (fileExist) {
            updateService(formData);
            resetAllFields();
            setDeleteSucces(false);
          }
        });
      } else {
        formData.append("file_id", null);
        if (fileExist) {
          updateService(formData);
          resetAllFields();
        }
      }
    } else {
      if (files && files.length !== 0) {
        files.forEach((file) => {
          formData.append("file_id", file);
          createNewService(formData);
          resetAllFields();
        });
      } else {
        setFileExist(true);
      }
    }
  };

  /**
   *
   * @param {*} data
   */
  async function createNewService(data) {
    let url = `${urlEnum.files}`;
    const res = await postData(url, data);
    if (!res || res?.error) {
      return;
    }
    props.getNewData();
    onClose(false);
  }

  /**
   *
   * @param {*} data
   * @param {*} id
   */
  async function updateService(data) {
    let url = `${urlEnum.files}/${props?.editData?.id}/${vehicleContext?.state?.vehicle?.id}/${props?.editData?.tip_fisier}`;
    const res = await postData(url, data);
    if (!res || res?.error) {
      return;
    }
    props.getNewData();
    onClose(false);
  }

  /**
   *
   */
  const resetAllFields = () => {
    onClose(false);
    if (!props.edit) {
      reset({
        date: moment(actualDate),
        descriere_financiar: "",
        tip_fisier: null,
        suma_financiar: "",
      });
    }
  };

  /**
   *
   * @param {*} idService
   * @param {*} idFile
   */
  const submitDeletedCar = () => {
    setFileExist(false);
    setOpenModalDelete(false);
  };

  /**
   *
   * @param {*} files
   */
  function handleChangeDropFile(files) {
    if (files.length !== 0) {
      setDeleteSucces(true);
      setFileExist(true);
      setFiles(files);
    }
  }
  /**
   *
   */
  function handleDeleteDropFile() {
    setFileExist(true);
    setFiles([]);
  }

  return (
    <Dialog
      key={props?.editData?.id}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: maxWidth ? maxWidth : 750,
          },
        },
      }}
    >
      <DialogTitle className={styles.dialogTitle}>
        <p className={styles.title}>{`${props.title}`}</p>
        <p className={styles.close}>
          <CloseIcon
            onClick={() => resetAllFields()}
            className={styles.closeIcon}
          />
        </p>
      </DialogTitle>
      <form
        onSubmit={handleSubmit(onSubmit)}
        method="get"
        id="formAddNewServices"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
            margin: 20,
            flexDirection: isMobile() ? "column" : "row",
            gap: 7,
          }}
        >
          <Controller
            name="tip_fisier"
            control={control}
            defaultValue={[]}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                style={{ width: "100%" }}
                {...field}
                size="small"
                disableClearable
                disablePortal
                getOptionLabel={(option) => option?.nume}
                isOptionEqualToValue={(option, value) =>
                  option?.nume === value?.nume
                }
                id="tip_fisier"
                onChange={(event, value) => field.onChange(value)}
                options={tipFisier}
                renderInput={(params) => (
                  <TextField
                    error={!!error}
                    helperText={error?.message}
                    id="tip_fisier"
                    label="Tip"
                    name="tip_fisier"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />

        </div>

        {props?.editData?.name && fileExist && !deleteSucces ? (
          <div key={deleteSucces} style={{ display: "flex" }}>
            <div style={{ marginTop: 10, marginLeft: 20 }}>
              {getIconByFileFileType(
                props?.editData?.file ? props?.editData?.file : "",
                100
              )}
            </div>
            <DeleteIcon
              onClick={() => setOpenModalDelete(true)}
              style={{
                backgroundColor: "#ff6633",
                color: "#fff",
                padding: 5,
                borderRadius: 7,
                cursor: "pointer",
              }}
            ></DeleteIcon>
          </div>
        ) : (
          <div
            style={{
              borderRadius: 5,
              margin: 20,
              border: fileExist ? "solid 1px #d32f2f" : "solid 1px #fff",
            }}
          >
            <Controller
              id="file_id"
              name="file_id"
              defaultValue={props?.editData?.file}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <>
                  <DropzoneArea
                    id="file_id"
                    nume="file_id"
                    acceptedFiles={["image/*,application/*"]}
                    dropzoneClass={style.dropzoneArea}
                    maxFileSize={30000000} //30 MB
                    filesLimit={1}
                    onDrop={(file) => {
                      handleChangeDropFile(file);
                    }}
                    inputRef={ref}
                    onDelete={handleDeleteDropFile}
                    {...field}
                  />
                </>
              )}
            ></Controller>
            {!fileExist ? (
              <p
                style={{
                  fontSize: 12,
                  marginLeft: 20,
                  color: "#d32f2f",
                }}
              >
                Este obligatoriu sa introduci un fisier!
              </p>
            ) : null}
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: 20,
          }}
        >
          <Button
            onClick={() => resetAllFields()}
            style={{
              color: "white",
              backgroundColor: "#4448",
            }}
          >
            Anuleaza
          </Button>
          <Button
            variant="contained"
            style={{ color: "white" }}
            color="primary"
            type="submit"
            form="formAddNewServices"
          >
            Salveaza
          </Button>
        </div>
        <GenericModal
          open={openModalImg}
          onClose={() => setOpenModalImg(false)}
          title=""
        >
          <div style={{ marginTop: 10, marginLeft: 20 }}>
            {getIconByFileFileType(
              props?.editData?.name ? props?.editData?.name : "",
              100
            )}
          </div>
        </GenericModal>
        <GenericModal
          open={openModalDelete}
          onClose={() => setOpenModalDelete(false)}
          title=""
        >
          <div style={{ textAlign: "center" }}>
            <p style={{ fontSize: 20 }}>Sunteti sigur ca doriti sa stergeti?</p>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                fullWidth
                color="secondary"
                style={{ margin: 10 }}
                onClick={() => setOpenModalDelete(false)}
              >
                {Vocabulary.no}
              </Button>
              <Button
                onClick={() => submitDeletedCar()}
                fullWidth
                variant="contained"
                style={{ color: "white", margin: 10 }}
              >
                {Vocabulary.yes}
              </Button>
            </div>
          </div>
        </GenericModal>
      </form>
    </Dialog>
  );
}

export default DocumentsForClients;
